
import { Toast } from 'vant'
import { defineComponent, reactive, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default defineComponent({
  setup() {
    const store = useStore()
    const router = useRouter()
    let state = reactive({
      detail: store.state.lineDetail
    })

    const goBack = () => {
      router.go(-1)
    }

    Toast.loading({
      duration: 500,
      message: '加载中...',
      forbidClick: true,
    });
    
    return {
      goBack,
      ...toRefs(state)
    }
  },
})
