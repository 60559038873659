<template>
  <div class="content">
    <van-nav-bar
      title="路线详情"
      left-arrow
      fixed
      safe-area-inset-top
      @click-left="goBack"
    />
    <div class="info">
      <span class="time"> {{detail.time}} </span>
      <span class="dist"> {{detail.dist}} </span>
      <span class="walk"> <i class="iconfont icon-walking"></i>{{detail.walk}} </span>
      <span class="price"> {{detail.price}} </span>
      <p class="from-to">
        {{ detail.startSta }} → {{ detail.endSta }}
      </p>
      <p class="title">{{ detail.title }}</p>
    </div>
    <div class="line-content">
      <div class="start">
        <div class="line"></div>
        <i class="iconfont icon-danseyuandian green"></i>
        起点 {{ detail.startSta }}
      </div>
      <div :class="['point', {'blue-background': item.stype === '1'}]" v-for="(item, index) in detail.lineList" :key="index">
        <template v-if="item.stype === '1'">
          <div class="line"></div>
          <i class="iconfont icon-danseyuandian blue"></i>
          {{item.staname}}
          <span v-if="item.msg == '上车站点'">(上车)</span>
          <span v-if="item.msg == '下车站点'">(下车)</span>
          <p class="bus" v-if="item.hcbus !== null">({{item.hcbus}})</p>
        </template>
        <template v-if="item.stype === '2'">
          <div class="line green"></div>
          <i class="iconfont icon-danseyuandian green"></i>
          {{item.msg}} 
        </template>
      </div>
      <div class="end">
        <div class="line"></div>
        <i class="iconfont icon-danseyuandian green"></i>
        终点 {{ detail.endSta }}
      </div>
    </div>
    <div class="tips small">部分数据来源：高德实时公交</div>
  </div>
</template>

<script lang="ts">
import { Toast } from 'vant'
import { defineComponent, reactive, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default defineComponent({
  setup() {
    const store = useStore()
    const router = useRouter()
    let state = reactive({
      detail: store.state.lineDetail
    })

    const goBack = () => {
      router.go(-1)
    }

    Toast.loading({
      duration: 500,
      message: '加载中...',
      forbidClick: true,
    });
    
    return {
      goBack,
      ...toRefs(state)
    }
  },
})
</script>

<style lang="less" scoped>
.content {
  padding: 92px 0;
  height: 100%;
  box-sizing: border-box;
}

.info {
  padding: 32px;
  font-size: 26px;
  .time {
    margin-right: 32px;
    font-size: 32px;
    color: #1989fa;
  }
  .dist {
    margin-right: 32px;
  }
  .walk {
    margin-right: 32px;
  }
}

.line-content {
  padding: 32px 0;
  font-size: 28px;
  color: #333;
  .start, .end {
    padding: 16px;
    position: relative;
    .line {
      position: absolute;
      height: 60px;
      border: 1px solid #07c160;
    }
  }
  .start {
    .line {
      top: 30px;
      left: 30px;
    }
  }
  .end {
    .line {
      bottom: 30px;
      left: 30px;
    }
  }

  .point {
    padding: 24px 16px;
    position: relative;
    .line {
      position: absolute;
      height: 88px;
      border: 1px solid #1989fa;
      top: 8px;
      left: 30px;
      &.green {
        border: 1px solid #07c160;
      }
    }
    .bus {
      position: absolute;
      top: 28px;
      left: 50px;
      font-size: 26px;
      color: rgb(130, 130, 130);
    }
  }
}
.green {
  color: #07c160;
}

.blue {
  color: rgb(25, 137, 250);
}

.blue-background {
  background-color: rgba(25, 137, 250, .1);
}

.tips {
  padding: 8px;
  background: @base-background-color;
  text-align: center;
  font-size: 24px;
  color: rgb(117, 117, 117);
  &.small {
    margin-top: 32px;
    font-size: 24px;
  }
}
</style>
